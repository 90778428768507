import Button from "@/components/button";
import Input from "@/components/input";
import Dropdown from "@/components/dropdown";
import TextArea from "@/components/text-area";
import Avatar from "@/components/avatar";
import Upload from "@/components/upload";
import Tag from "@/components/tag";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import draggable from "vuedraggable";
import {caseApi} from "../../main.js";

export default {
    name: "product",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-dropdown": Dropdown,
        "neo-text-area": TextArea,
        "neo-avatar": Avatar,
        "neo-upload": Upload,
        "neo-tag": Tag,
        multiselect: Multiselect,
        draggable,
    },
    props: [],
    data() {
        return {
            alertBox: {
                message: "",
                error: "",
            },
            highlite: false,
            drag: false,
            previewImage: null,
            products: [],
            workflows: [],
            org_fields: [],
            stages: [],
            selected_workflow: "",
            fieldControl: {
                field_id: "",
                order: null,
                optional: null,
            },
            productControl: {
                name: "",
                description: "",
                product_icon: "",
                turn_around_time: null,
                product_prefix: "",
                workflow_id: "",
                product_fields: [],
            },
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    async mounted() {
        let workflow_resp = await caseApi.get("/api/v1/workflows");
        this.workflows = workflow_resp.data;

        let org_field_resp = await caseApi.get("/api/v1/fields");
        this.org_fields = org_field_resp.data;

        this.stages.push({
            name: "",
            fields: [],
            field_sets: [],
        });
    },
    methods: {
        getStages() {
            var stages = [];
            for (let stage of this.stages) {
                var current_satge = [];
                if (!stage.name) {
                    return [[]];
                }
                // fields without field set
                for (var [stage_field_index, field] of stage.fields.entries()) {
                    field.field_stage = stage.name;
                    field.order = (stage_field_index + 1) * 10;
                    current_satge.push(field);
                }

                // fields with field set
                for (let field_set of stage.field_sets) {
                    if (!field_set.name) {
                        return [[]];
                    }
                    for (let [fieldset_field_index, fieldset_field] of field_set.fields.entries()) {
                        fieldset_field.field_stage = stage.name;
                        fieldset_field.field_set = field_set.name;
                        fieldset_field.order = (fieldset_field_index + 1) * 10;
                        current_satge.push(fieldset_field);
                    }
                }
                stages.push(current_satge);
            }
            return stages;
        },

        closeAlertBox() {
            this.alertBox.message = "";
        },

        async saveProduct() {
            // this.alertBox.error = "Product:" + this.productControl.name
            // // setTimeout(() => this.alertBox.error = "", 3000);

            this.highlite = true;
            var stages = this.getStages();
            this.productControl.workflow_id = this.selected_workflow.id;
            this.productControl.turn_around_time = this.productControl.turn_around_time ? parseInt(this.productControl.turn_around_time) : null;
            this.productControl.product_fields = stages;
            for (const [key, val] of Object.entries(this.productControl)) {
                if (key === "product_icon") {
                    continue;
                }
                if (!val) {
                    return;
                }
            }
            for (let item of stages) {
                if (item.length === 0) {
                    return;
                }
            }

            try {
                var resp = await caseApi.post("/api/v1/products", this.productControl);
                this.alertBox.message = "Product: " + this.productControl.name;
                setTimeout(() => (this.alertBox.message = ""), 3000);
            } catch (error) {
                if (error.response.status === 400) {
                    this.alertBox.error = "Product with same name or same prefix exists";
                } else {
                    this.alertBox.error = "code: " + error.response.status + ", message: " + "Can't create product";
                }
                setTimeout(() => (this.alertBox.error = ""), 3000);
            }
        },

        addStage() {
            this.stages.push({
                name: "",
                fields: [],
                field_sets: [],
            });
        },
        deleteStage(index) {
            this.stages.splice(index, 1);
        },

        addFieldSet(stage) {
            stage.field_sets.push({
                name: "",
                fields: [],
            });
        },

        deleteSet(stage, field_set_index) {
            stage.field_sets.splice(field_set_index, 1);
        },

        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                this.productControl.product_icon = e.target.result;
            };
        },
        addField(stage, field) {
            var field_exists = false;
            for (let item of stage.fields) {
                if (item.label === field.label) {
                    field_exists = true;
                }
            }

            if (!field_exists) {
                var l = stage.fields.length;
                var c_field = JSON.parse(JSON.stringify(field));
                c_field.order = (l + 1) * 10;
                c_field.optional = true;
                stage.fields.push(c_field);
            }
        },
        removeField(stage, index) {
            stage.fields.splice(index, 1);
        },
        // printOrder(stage) {
        // },

        markMandatory(stage, field_index) {
            stage.fields[field_index].optional = !stage.fields[field_index].optional;
            this.$forceUpdate();
        },
    },
};
